
import 'bootstrap';
// eslint-disable-next-line no-undef
global.moment = require('moment');
require('tempusdominus-bootstrap-4');
import './plugins/tinymce-placeholder';
import create from '@verndale/core';

// create modules
const modules = [
  { name: 'ItemSelector', loader: () => import('./components/ItemSelector') },
  { name: 'ItemList', loader: () => import('./components/ItemList') },
  { name: 'ImagePreview', loader: () => import('./components/ImagePreview') }
];

function keepAlive() {
  $.get('/api/KeepAlive');
  setTimeout(keepAlive.refresh, 60000);
}

$(document).ready(() => {
  create(modules);

  if ($('body').data('modal')) {
    $('#thankYouModal').modal('show');
  }

  $('.datetimepicker-input').each((i, picker) => {
    const format = 'MM/DD/YYYY HH:mm A';
    // eslint-disable-next-line no-undef
    const date = moment($(picker).val(), format).toDate();

    $(picker).datetimepicker({
      collapse: false,
      format,
      date
    });
  });

  tinymce.init({
    selector: 'textarea:not(.simple-rtf):not(.job-rtf):not(.no-rtf)',
    branding: false,
    plugins: 'link',
    convert_urls: false
  });

  tinymce.init({
    selector: 'textarea.simple-rtf',
    branding: false,
    plugins: 'link',
    width: 514,
    max_width: '100%',
    menubar: false,
    toolbar: ['undo redo | bold italic underline | link'],
    convert_urls: false
  });

  tinymce.init({
    selector: 'textarea.job-rtf',
    branding: false,
    plugins: 'link lists',
    width: 514,
    max_width: '100%',
    menubar: false,
    toolbar: ['numlist bullist | undo redo | bold italic underline | link'],
    convert_urls: false
  });

  $('[data-toggle="offcanvas"]').on('click', () => {
    $('.offcanvas-collapse').toggleClass('open')
  })

  $('[data-toggle="popover"]')
    .on('click', e => false)
    .popover({
      trigger: 'focus',
      template: '<div class="tooltip" role="tooltip"><a class="tooltip-close"></a><div class="popover-body"></div></div>'
    });

  $('[data-confirm]').on('click', evt => {
    const { currentTarget } = evt;
    evt.preventDefault();

    // eslint-disable-next-line no-undef
    if (confirm($(currentTarget).data('confirm'))) {
      window.location.href = $(currentTarget).attr('href');
    }
  });

  $('.global-sidebar__navbar a.active')
    .parents('ul')
    .siblings('a')
    .addClass('open');


  $('.level-2').each(function() {
    $(this).html(`-${$(this).text()}`);
  });
  $('.level-3').each(function() {
    $(this).html(`--${$(this).text()}`);
  });
  $('.level-4').each(function() {
    $(this).html(`---${$(this).text()}`);
  });

  keepAlive();
});
